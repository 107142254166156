import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc225bb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dvPaper" }
const _hoisted_2 = { class: "dvLeftQuestion" }
const _hoisted_3 = { class: "dvLeftBottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_choice_question = _resolveComponent("choice-question")!
  const _component_multiple_question = _resolveComponent("multiple-question")!
  const _component_sheet_music_creation = _resolveComponent("sheet-music-creation")!
  const _component_true_or_false_question = _resolveComponent("true-or-false-question")!
  const _component_sheet_music_song = _resolveComponent("sheet-music-song")!
  const _component_sheet_music_sing = _resolveComponent("sheet-music-sing")!
  const _component_connect_question = _resolveComponent("connect-question")!
  const _component_fills_in_question = _resolveComponent("fills-in-question")!
  const _component_brief_answer_question = _resolveComponent("brief-answer-question")!
  const _component_nesting_question = _resolveComponent("nesting-question")!
  const _component_jigsaw_question = _resolveComponent("jigsaw-question")!
  const _component_drag_question = _resolveComponent("drag-question")!
  const _component_color_wheel_question = _resolveComponent("color-wheel-question")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.question.type === 1)
        ? (_openBlock(), _createBlock(_component_choice_question, {
            key: _ctx.question.FPSubmitDate,
            ref: "questionRef",
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 2)
        ? (_openBlock(), _createBlock(_component_multiple_question, {
            key: _ctx.question.FPSubmitDate,
            ref: "questionRef",
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 3)
        ? (_openBlock(), _createBlock(_component_sheet_music_creation, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef",
            onError: _ctx.onQuestionCompError
          }, null, 8, ["question", "disabled", "onError"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 4)
        ? (_openBlock(), _createBlock(_component_true_or_false_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef"
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      ([5, 6].includes(_ctx.question.type))
        ? (_openBlock(), _createBlock(_component_sheet_music_song, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef",
            onError: _ctx.onQuestionCompError
          }, null, 8, ["question", "disabled", "onError"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 7)
        ? (_openBlock(), _createBlock(_component_sheet_music_sing, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef",
            onError: _ctx.onQuestionCompError
          }, null, 8, ["question", "disabled", "onError"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 8)
        ? (_openBlock(), _createBlock(_component_connect_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef"
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 9)
        ? (_openBlock(), _createBlock(_component_fills_in_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef"
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 10)
        ? (_openBlock(), _createBlock(_component_brief_answer_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef"
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 11)
        ? (_openBlock(), _createBlock(_component_nesting_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            showQuestionNum: false,
            showQuestionScore: false,
            disabled: _ctx.questionDisable,
            ref: "questionRef",
            onError: _ctx.onQuestionCompError
          }, null, 8, ["question", "disabled", "onError"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 14)
        ? (_openBlock(), _createBlock(_component_jigsaw_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            disabled: _ctx.questionDisable,
            showQuestionNum: false,
            showQuestionScore: false,
            ref: "questionRef"
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 12)
        ? (_openBlock(), _createBlock(_component_drag_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            disabled: _ctx.questionDisable,
            showQuestionNum: false,
            showQuestionScore: false,
            ref: "questionRef"
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.question.type === 13)
        ? (_openBlock(), _createBlock(_component_color_wheel_question, {
            key: _ctx.question.FPSubmitDate,
            question: _ctx.question,
            disabled: _ctx.questionDisable,
            showQuestionNum: false,
            showQuestionScore: false,
            ref: "questionRef"
          }, null, 8, ["question", "disabled"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "dvBtn mr10",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getPrevious && _ctx.getPrevious(...args)))
      }, "上一题"),
      _createElementVNode("button", {
        class: "dvBtn mr10",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.getNext && _ctx.getNext(...args)))
      }, "下一题"),
      _createElementVNode("button", {
        class: "btnBack mr3",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToFreePracticeRecord && _ctx.goToFreePracticeRecord(...args)))
      }, "返回")
    ])
  ]))
}