
import { defineComponent, ref, onBeforeMount, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Countdown from "vue3-countdown";
import CustomAudio from "@/components/CustomAudio.vue";
import ChoiceQuestion from "@/components/ChoiceQuestion.vue";
import MultipleQuestion from "@/components/MultipleQuestion.vue";
import TrueOrFalseQuestion from "@/components/TrueOrFalseQuestion.vue";
import SheetMusicCreation from "@/components/SheetMusicCreation.vue";
import SheetMusicSing from "@/components/SheetMusicSing.vue";
import SheetMusicSong from "@/components/SheetMusicSong.vue";
import BriefAnswerQuestion from "@/components/BriefAnswerQuestion.vue";
import FillsInQuestion from "@/components/FillsInQuestion.vue";
import ConnectQuestion from "@/components/ConnectQuestion.vue";
import NestingQuestion from "@/components/NestingQuestion.vue";
import JigsawQuestion from "@/components/JigsawQuestion.vue";
import DragQuestion from "@/components/DragQuestion.vue";
import ColorWheelQuestion from "@/components/ColorWheelQuestion.vue";
import { useStore } from "@/store";
import { MutationNames } from "@/store/index";
import _ from "lodash";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
export default defineComponent({
  components: {
    Countdown,
    CustomAudio,
    ChoiceQuestion,
    MultipleQuestion,
    TrueOrFalseQuestion,
    SheetMusicCreation,
    SheetMusicSing,
    SheetMusicSong,
    BriefAnswerQuestion,
    FillsInQuestion,
    ConnectQuestion,
    NestingQuestion,
    JigsawQuestion,
    DragQuestion,
    ColorWheelQuestion,
  },
  setup() {
    let loading: any = ref("");
    const questionRef: any = ref<null | HTMLElement>(null);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    let questionInfo: any = ref(store.state.freePracticeRecordSubjectData); // 所有题目信息
    let question: any = ref({}); // 当前选中题目信息
    let subjectData: any = ref(route.query.subjectData); // 当前选中题目信息
    let isErrorQuestionType: any = ref(route.query.isErrorQuestionType); // 只看错题标识
    let questionId: any = ref(0); // 当前题目数组下标
    let btnNextShow = ref(true); // 下一题按钮状态
    let btnPreviousShow = ref(true); // 上一题按钮状态
    let questionDisable = ref(true); // 题目能否编辑状态 true:不可编辑｜｜false:可以编辑

    const goBack = () => {
      router.back();
    };
    const goToFreePracticeRecord = () => {
      // router.back();
      router.push({
        path: "/freePracticeRecord",
        query: {
          isErrorQuestionType: isErrorQuestionType.value.toString(),
        },
      });
    };

    const next = () => {
      if (questionId.value + 1 >= questionInfo.value.length) {
        btnNextShow.value = false;
        ElMessage({
          type: "warning",
          center: true,
          message: "已是最后一题！",
        });
      } else {
        questionId.value++;
        question.value = questionInfo.value[questionId.value];
      }
    };

    const previous = () => {
      if (questionId.value === 0) {
        btnPreviousShow.value = false;
        ElMessage({
          type: "warning",
          center: true,
          message: "已是第一题！",
        });
      } else {
        questionId.value--;
        question.value = questionInfo.value[questionId.value];
      }
    };

    // 下一题
    const getNext = _.throttle(async () => {
      if (question.value.type !== 11) {
        // btnPreviousShow.value = true;
        next();
      } else {
        const { isLastSubQuestion, changeSubQuestion } = questionRef.value;
        if (isLastSubQuestion()) {
          next();
        } else {
          changeSubQuestion("next");
        }
      }
    }, 800);
    // 上一题
    const getPrevious = _.throttle(async () => {
      if (question.value.type !== 11) {
        previous();
      } else {
        const { isFirstSubQuestion, changeSubQuestion } = questionRef.value;
        if (isFirstSubQuestion()) {
          previous();
        } else {
          changeSubQuestion("previous");
        }
      }
      // btnNextShow.value = true;
      // if (questionId.value === 0) {
      //   btnPreviousShow.value = false;
      //   ElMessage({
      //     type: "warning",
      //     center: true,
      //     message: "已是第一题！",
      //   });
      // } else {
      //   questionId.value--;
      //   question.value = questionInfo.value[questionId.value];
      // }
    }, 800);

    const onQuestionCompError = (error: any) => {
      const msg = typeof error === "string" ? error : error.message;
      console.log("onQuestionCompError");
      ElMessageBox.confirm(`${msg}`, "系统出错", {
        confirmButtonText: "确定",
        type: "warning",
        center: true,
        showClose: false,
        showCancelButton: false,
        closeOnClickModal: false,
        customClass: "exam-score-message-box",
      });
    };
    onBeforeMount(() => {
      let num = 0;
      questionInfo.value.forEach((value: any) => {
        if (value.FPSubmitDate === subjectData.value) {
          questionId.value = num;
          question.value = questionInfo.value[num];
        }
        num++;
      });
    });

    // onMounted(() => {
    //   console.log("paperListqqq", question);
    // });
    return {
      questionRef,
      question,
      btnPreviousShow,
      btnNextShow,
      getNext,
      getPrevious,
      goBack,
      questionDisable,
      goToFreePracticeRecord,
      onQuestionCompError,
    };
  },
});
