// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/paper/bj-exam.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".dvPaper[data-v-cc225bb8]{width:100vw;height:100vh}.dvPaper .dvLeftQuestion[data-v-cc225bb8]{width:100vw;height:100vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100% 100%;display:flex;padding:2.5rem 2.5rem 2.5rem 3.125rem;box-sizing:border-box}.dvPaper .dvLeftBottom[data-v-cc225bb8]{width:40%;height:11vh;text-align:right;position:absolute;bottom:0;right:0;display:flex;justify-content:flex-end;align-items:center}.dvPaper .dvLeftBottom .btnBack[data-v-cc225bb8]{width:20%;height:70%;font-size:1.875rem;font-weight:700;color:#fff;border:0;background:linear-gradient(90deg,#ec5050 0,#ff6c48);border-radius:.9375rem}.dvPaper .dvLeftBottom .dvBtn[data-v-cc225bb8]{width:25%;height:70%;background-color:#f7f7f7;border-radius:.9375rem;border:0;font-size:1.875rem;font-weight:400;font-stretch:normal;color:#1ac36e;outline-width:0;cursor:pointer}.dvPaper .dvLeftBottom .dvBtn[data-v-cc225bb8]:active{outline-width:0;outline-color:transparent;border-radius:.9375rem;border:0;background-color:hsla(0,0%,100%,.3764705882352941)}.dvPaper .mr10[data-v-cc225bb8]{margin-right:10%}.dvPaper .mr3[data-v-cc225bb8]{margin-right:3%}", ""]);
// Exports
module.exports = exports;
